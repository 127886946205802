console.log('==================================')
console.log('♥♥♥ default app main.js loaded ♥♥♥')
console.log('==================================')


import Vue from 'vue'
import axios from 'axios'
import config from '../../config'
Vue.prototype.$appConfig = config
Vue.prototype.$http = axios

const isProduction = config.node_env === 'production'
const isDev = config.node_env === 'development'


// // Injecting ENV variable to the App config, for Stage / Prod distinction
// if (isProduction) {
// // if (true) {  // MEMO: uncomment for testing on webpack Dev-Server
//   const knownEnvironments = Object.freeze([
//     'production',
//     'stage'
//   ])
//   const knownStaticHosts = Object.freeze({
//     production: 'https://cdn.skillfolio.ru/',
//     stage: 'http://test.stage.skillfolio.ru/',
//   })
//   // const customStaticBaseUsers = Object.freeze([
//   //   'stage',
//   // ])
//   axios.get('/env.json')
//     .then(response => {
//       console.log('env.json response:', response)
//       if (response.data.ENVIRONMENT && knownEnvironments.includes(response.data.ENVIRONMENT)) {
//         console.log(`config.node_env == production and config.environment == ${response.data.ENVIRONMENT}`)
//         config.environment = response.data.ENVIRONMENT
//         console.log('Changed config.environment to:', config.environment)
//         config.static_host = knownStaticHosts[config.environment]
//         console.log('Changed config.static_host to:', config.static_host)
//         // if (customStaticBaseUsers.includes(config.environment)) {
//         const baseTag = document.createElement('base')
//         baseTag.href = config.static_host
//         console.log(`Added ${config.environment} environment baseTag:`, baseTag)
//         document.head.appendChild(baseTag)
//         // }
//       } else {
//         console.warn('Express APi answered with unknown value!')
//       }
//     })
//     .catch(err => {
//       console.log('/env.json answered with err:', err)
//       // // MEMO: uncomment next lines for testing on webpack dev-server
//       // const answer = 'production'
//       // config.environment = answer
//       // console.log('Changed config.environment to:', config.environment)
//       // config.static_host = knownStaticHosts[config.environment]
//       // console.log('Changed config.static_host to:', config.static_host)
//       // // if (customStaticBaseUsers.includes(config.environment)) {
//       // const baseTag = document.createElement('base')
//       // baseTag.href = config.static_host
//       // console.log(`Added ${config.environment} environment baseTag:`, baseTag)
//       // document.head.appendChild(baseTag)
//     })
// }


import '../../styles.less'
// const devStyles = () => {
//   console.log('!!! devStyles:', devStyles)
//   if (config.)
//     return import('../../styles-dev.vue')
// }

import App from './App.vue'

import '../../apploader.less'
import initProgress from '../../apploader.js'


import router from './router/router'
initProgress(router)
import store from '../../store/store'


import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

if (isDev) {
  console.warn('Sentry initialization disabled for dev-server')
} else {
  Sentry.init({
    dsn: 'https://9715d28fd12e432ca8051e152bf1e929@s.io.lv/36',
    environment: config.environment,
    release: 'common-test@' + config.package_version,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
  })
}


// Plugins //

// used in ResultPracticum
import VueCollapse from 'vue2-collapse'
Vue.use(VueCollapse)
// manually add styles to App.vue


import VueResize from 'vue-resize'
Vue.use(VueResize)

import VuePapaParse from 'vue-papa-parse'
Vue.use(VuePapaParse)

Vue.directive('visible', function (el, binding) {
  el.style.visibility = !!binding.value ? 'visible' : 'hidden';
});

import VeeValidate, { Validator } from 'vee-validate'
import ru_validator from 'vee-validate/dist/locale/ru'
// import messagesRu from '../../validator/messages/ru.js';
// import attributesRu from '../../validator/attributes/ru.js'
// import attributesEn from '../../validator/attributes/en.js'
Vue.use(VeeValidate, {
  events: 'blur',
  // locale: 'ru',
  // dictionary: {
  //   // en: { attributes: attributesEn },
  //   ru: { messages: messagesRu, attributes: attributesRu }
  // }
})
// Override the default message.
Validator.extend('alpha-dash-space', {
  getMessage: field => 'The field ' + field + ' needs only letters, space and dashes',
  validate: value => Boolean(value.match(/((^[A-ZА-Я])(([A-ZА-Я]*)([\s-]){0,1})((([A-ZА-Я]+)([\s-]){0,1})*)([A-ZА-Я]$))/i))
})
Validator.extend('phone', {
  getMessage: field => 'The field ' + field + ' needs phone number',
  validate: value => Boolean(value.match(/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/i))
})
Object.assign(ru_validator.messages, {
  'alpha-dash-space': value => 'В поле ' + value + ' должны быть только буквы, тире и пробелы ',
  'phone': value => 'В поле ' + value + ' должны быть только номера телефонов ',
})
Validator.localize('ru', {
  ...ru_validator,
  attributes: {
    firstname: 'имя',
    lastname: 'фамилия',
    region: 'регион',
    subscribe: 'подписка',
    licence_agreement: 'лицензионное соглашение',
    privacy_policy: 'политика приватности',
    gender: 'пол',
    age: 'возраст',
    free_form: 'текстовый ответ',
    school: 'школа',
    grade: 'класс',
    subject: 'предмет',
    'school-work-experience': 'опыт работы в школе',
    'company-work-experience': 'опыт работы в компании',
    'work-experience': 'опыт работы',
    education: 'образование',
    profession: 'профессия',
    'company-scope': 'направление деятельности компании',
    'child-age': 'возраст Вашего ребёнка',
    inn: 'ИНН',
    birthday: 'дата рождения',
    city: 'город проживания',
    phone: 'номер телефона',
    'promo-code': 'промокод',
  },
})

import VModal from 'vue-js-modal'
Vue.use(VModal, {
  // dynamic: true,
  // dynamicDefaults: { clickToClose: false },
  // injectModalsContainer: true
})

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import VueLogger from 'vuejs-logger'
const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: false,
  showMethodName: false,
  separator: '|',
  showConsoleColors: true
}
Vue.use(VueLogger, options)


// Custom plugins //

// created hyphenation plugin
import hyphenation from '../../hyphenation'
// console.log('hyphenation.hyphenate in main.js:', hyphenation.hyphenate)
const hyphenationPlugin = {
  install (Vue) { //, options
    Vue.prototype.$hyphenate = hyphenation.hyphenate // we use $ because it's the Vue convention
  }
}
Vue.use(hyphenationPlugin)

// created md5 plugin
import md5 from 'blueimp-md5'
const md5Plugin = {
  install (Vue) {
    Vue.prototype.$md5 = md5
  }
}
Vue.use(md5Plugin)


// Global components //

import Progressbar from '../../components/Progressbar'
import Progressbarmini from '../../components/Progressbarmini'
import DependentSelect from '../../components/DependentSelect.vue'

Vue.component('progressbar', Progressbar)
Vue.component('progressbarmini', Progressbarmini)
Vue.component('DependentSelect', DependentSelect)

// Global filters //

import DateFilter from '@/filters/date'
import CapitalizeFilter from '@/filters/capitalize'
import LowercaseFilter from '@/filters/lowercase'
import UppercaseFilter from '@/filters/uppercase'
Vue.filter('date', DateFilter)
Vue.filter('capitalize', CapitalizeFilter)
Vue.filter('lowercase', LowercaseFilter)
Vue.filter('uppercase', UppercaseFilter)


Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
